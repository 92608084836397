import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AIResearchAndDevelopment from "./components/AIResearchAndDevelopment";
import AboutUs from "./components/AboutUs";
import CaseStudies from "./components/CaseStudies";
import ContactUs from "./components/ContactUs";
import DataEngineeringServices from "./components/DataEngineeringServices";
import DataScienceSolutions from "./components/DataScienceSolutions";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import JoinUs from "./components/JoinUs";
import MissionSection from "./components/MissionSection";
import OurApproach from "./components/OurApproach";
import OurExpertise from "./components/OurExpertise";
import OurServices from "./components/OurServices";
import Testimonials from "./components/Testimonials";
import ValuesSection from "./components/ValuesSection";
import WhyChooseUs from "./components/WhyChooseUs";
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import RefundPolicy from './pages/RefundPolicy';
import ContactUsPage from './pages/ContactUsPage';
import ShippingDeliveryPolicy from './pages/ShippingDeliveryPolicy';
import PricingPolicy from './pages/PricingPolicy';

function Home() {
  return (
    <>
      <Header />
      <div id="hero"><HeroSection /></div>
      <div id="about"><AboutUs /></div>
      <div id="mission"><MissionSection /></div>
      <div id="values"><ValuesSection /></div>
      <div id="data-science-solutions"><DataScienceSolutions /></div>
      <div id="ai-research"><AIResearchAndDevelopment /></div>
      <div id="data-engineering-services"><DataEngineeringServices /></div>
      <div id="why-choose-us"><WhyChooseUs /></div>
      <div id="case-studies"><CaseStudies /></div>
      <div id="our-services"><OurServices /></div>
      <div id="our-expertise"><OurExpertise /></div>
      <div id="testimonials"><Testimonials /></div>
      <div id="contact"><ContactUs /></div>
      <div id="join-us"><JoinUs /></div>
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/pricing-policy" element={<PricingPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/shipping-policy" element={<ShippingDeliveryPolicy />} />

      </Routes>
    </Router>
  );
}

export default App;
