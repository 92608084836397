import React from 'react';
import Header from '../components/Header';

const PricingPolicy = () => {
  return (
    <>
    <Header />
    <section className="bg-gray-900 w-full text-white py-20 flex items-center justify-center min-h-screen">
      <div className="w-full max-w-6xl px-6 text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-6 text-blue-400">
          Pricing Policy
        </h2>
        <p className="text-lg md:text-xl mb-8">
        Annual Plan - ₹4,999/year
        </p>
        <ul className="list-disc list-inside text-lg md:text-xl space-y-4">
          <li>
          ✔️ Unlimited uploads
          </li>
          <li>
          ✔️ No hidden charges
          </li>
          <li>
          ✔️ Priority support
          </li>
          <li>
          ✔️ Access to all premium features
          </li>
        </ul>
        <p className="text-lg md:text-xl mt-8">
        One simple price. Full access. No limits.
        </p>
      </div>
    </section>
    </>
  );
};

export default PricingPolicy;
